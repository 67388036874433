.bg-dark {
    background-color: #00347d !important;
    color: #fff !important;
}

.bg-dark.social__link--style1 svg,
.social__link--style1 i {
    color: #ffffff !important;
}

.bg-body-tertiary {
    background-color: #ffffff !important;
    backdrop-filter: blur(5px) !important;
}

.bg-body-tertiary.dark {
    background-color: #0000005c !important;
    backdrop-filter: blur(5px) !important;
}

a {
    text-decoration: none !important;
    color: black !important;
}

.trk-btn--primary {
    background-color: #2278ed !important;
    border: 1px solid #2278ed !important;
}

.trk-btn--primary.nav-link {
    color: #fff !important;
}

.trk-btn {
    padding: 0.8rem 1.35rem !important;
}

.text-purple {
    color: #6f42c1;
}

.text-teal {
    color: #20c997;
}

.step-1 {
    display: flex;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    cursor: pointer;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding-top: 56px;
    padding-bottom: 64px;
    padding-left: 24px;
    padding-right: 24px;
    border-radius: 24px;
    min-width: 288px;
    background: linear-gradient(60deg, #185B64 0%, #114147 100%);
    color: #fff;
}

.step-1.dark {
    background: linear-gradient(60deg, #00d7f4 0%, #106c78 100%);
}

.rest-3-step {
    display: flex;
    align-items: stretch;
    gap: 64px;
    background-color: #FFFFFF;
    box-shadow: 0 32px 80px 0 #18333d1f;
    padding: 30px 20px;
    border-radius: 20px;
}

.rest-3-step.dark {
    background-color: #0c263a;
}

.feature.dark {
    background-color: #0d3c5f;
}

.features-item.dark {
    color: #ffffff !important;
    background-color: #008cff !important;
}

.features-item.dark.active {
    color: #ffffff !important;
    background-color: #00579d !important;
}

.step-number {
    background-color: rgba(14, 32, 38, 0.05);
    width: 48px;
    height: 48px;
    border-radius: 50%;
    font-size: 12px;
    color: rgba(14, 32, 38, 0.4);
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    margin: 0 auto;
    margin-bottom: 24px;
    -webkit-transition: all 0.3s ease-out;
    transition: all 0.3s ease-out;
}

.step-number.dark {
    background-color: rgb(0, 46, 61);
    color: #fff;
}

.list-style-disc {
    list-style: disc;
}

.footer__linklist-item a {
    -webkit-transition: all 0.3s ease-out;
    -moz-transition: all 0.3s ease-out;
    -ms-transition: all 0.3s ease-out;
    -o-transition: all 0.3s ease-out;
    transition: all 0.3s ease-out;
    color: rgba(255, 255, 255, 0.6) !important;
}

.nav-link {
    font-weight: normal;
}

.nav-link.active {
    font-weight: 500;
    color: #2278ed !important;
}

.account-step.dark {
    background: lightblue;
}

.account-step:hover {
    border: 2px solid #007d8b;
}

.w-35 {
    width: 35px;
}

.h-35 {
    height: 35px;
}

.preloader {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #fff;
    /* or any other color */
    z-index: 9999;
}

.preloader img {
    width: 100px;
    /* Adjust the size as needed */
    height: auto;
}

@media (min-width: 1200px) {
    .container {
        max-width: 1170px;
    }
}

@media (min-width: 1400px) {
    .container {
        max-width: 1370px;
    }
}

.hZUxNm {
    font-size: 16px;
    font-weight: 700;
}

.footer__linklist {
    padding-left: 0px;
}

.page {
    padding: 85px 0px;
}

.page h2,
.page h3,
.page p {
    color: #000000;
}

.page.dark h2,
.page.dark h3,
.page.dark p {
    color: #fff;
}

.bg_welcome {
    background-size: cover;
    background-repeat: no-repeat;
    padding: 20px;
}

.rounded-30 {
    border-radius: 30px;
}

.main-coin {
    cursor: pointer;
}

.input-group-text {
    min-width: 90px;
}

.welcome_content {
    width: 50%;
    position: relative;
    padding: 50px 100px;
    background-color: hsla(0, 0%, 100%, .2);
    backdrop-filter: blur(12px);
    -webkit-backdrop-filter: blur(12px);
    margin: 0px auto;
    border: 1px solid rgb(185 187 203 /1);
    border-radius: 15px;
    text-align: center;
}

.coin-icons {
    display: flex;
    align-items: center;
    justify-content: center;
}

.from-coin,
.to-coin {
    display: flex;
    align-items: center;
}

#trade-tabs.nav-tabs .nav-link.active {
    background-color: #a6daf5 !important;
    border-color: #a9e2ff !important;
}

#trade-tabs.nav-tabs .nav-link {
    padding: 10px;
}

#trade-tabs {
    border-bottom: none !important;
}

.trade-tabs-tab-buy {
    padding: 15px 0px;
}

#userKYC.nav-tabs .nav-link.active {
    background-color: #fafcfd !important;
    border-color: #2200ff5c !important;
}

#userKYC .nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link {
    background-color: #fafcfd !important;
}

#userKYC.nav-tabs .nav-link {
    padding: 40px;
}

.coin-symbol {
    height: 20px;
    width: 20px;
}

.coin-icons {
    display: flex;
    justify-content: center;
    align-items: center;
}

.from-coin {
    margin-right: 10px;
}

.to-coin {
    margin-left: 10px;
}

@keyframes floating-btc {
    0% {
        transform: translateY(0);
    }

    50% {
        transform: translateY(-30px);
    }

    100% {
        transform: translateY(0);
    }
}

.animate-floating-btc {
    -webkit-animation: floating-btc 4s ease-in-out infinite;
    animation: floating-btc 4s ease-in-out infinite;
}

@keyframes floating-busd {
    0% {
        transform: translateY(0);
    }

    50% {
        transform: translateY(10px);
    }

    100% {
        transform: translateY(0);
    }
}

.animate-floating-busd {
    -webkit-animation: floating-busd 4s ease-in-out infinite;
    animation: floating-busd 4s ease-in-out infinite;
}

@keyframes floating-eth {
    0% {
        transform: translateY(0);
    }

    50% {
        transform: translateY(-20px);
    }

    100% {
        transform: translateY(0);
    }
}

.animate-floating-eth {
    -webkit-animation: floating-eth 4s ease-in-out infinite;
    animation: floating-eth 4s ease-in-out infinite;
}

@keyframes floating-bnb {
    0% {
        transform: translateY(0);
    }

    50% {
        transform: translateY(-10px);
    }

    100% {
        transform: translateY(0);
    }

}

.animate-floating-bnb {
    -webkit-animation: floating-bnb 4s ease-in-out infinite;
    animation: floating-bnb 4s ease-in-out infinite;
}

@keyframes floating-zarp {
    0% {
        transform: translateY(0);
    }

    50% {
        transform: translateY(10px);
    }

    100% {
        transform: translateY(0);
    }

}

.animate-floating-zarp {
    -webkit-animation: floating-zarp 4s ease-in-out infinite;
    animation: floating-zarp 4s ease-in-out infinite;
}

@keyframes floating-usdt {
    0% {
        transform: translateY(0);
    }

    50% {
        transform: translateY(20px);
    }

    100% {
        transform: translateY(0);
    }
}

.animate-floating-usdt {
    -webkit-animation: floating-usdt 4s ease-in-out infinite;
    animation: floating-usdt 4s ease-in-out infinite;
}

@keyframes floating-usdc {
    0% {
        transform: translateY(0);
    }

    50% {
        transform: translateY(-10px);
    }

    100% {
        transform: translateY(0);
    }

}

.animate-floating-usdc {
    -webkit-animation: floating-usdc 4s ease-in-out infinite;
    animation: floating-usdc 4s ease-in-out infinite;
}

.coinList {
    position: absolute;
    margin-top: -20px;
    background: #fff;
    max-height: 200px;
    width: 100%;
    border: 1px solid #e0e0e094;
    padding: 15px;
    overflow-y: auto;
    overflow-x: hidden;
    box-sizing: border-box;
    z-index: 999999;
    max-width: 293px;
}

.feature-img {
    height: 400px;
}

.features-item {
    color: #000 !important;
    background-color: #ffffff !important;
    padding: 15px 20px;
    border: 1px solid #d6d6d661;
    font-size: 18px;
    font-weight: 400;
    margin-bottom: 5px;
}

.features-item.active {
    color: #000 !important;
    background-color: #ffffff !important;
    padding: 15px 20px;
    border: 1px solid #d6d6d661;
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 5px;
}